<template>
  <IconRegister />
  <MyProgressBar />
  <LoadingToast
    :show="device.loading || userResponse.loading"
    :z-index="99999"
  />

  <div v-show="!device.scanner">
  <!-- 不需 token 的路由 -->
  <router-view v-if="route.meta.noAuth" />
  <!-- 需要 token 的路由 -->
  <router-view
    v-slot="{ Component }"
    v-else-if="user.userData"
    :key="user.token"
  >
    <keep-alive :include="keepAliveIncludes">
      <component :is="Component"  v-if="route.meta.keepAlive" :key="route.name"/>
    </keep-alive>
    <component :is="Component"  v-if="!route.meta.keepAlive" :key="route.name+routerKey" />
  </router-view>
  </div>

  <Scanner
    v-if="device.scanner"
    @close="device.setScanner(false)"
    @success="onSuccess"
  />
   <SKUPC v-if="device.isPC"></SKUPC>

</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { Dialog } from 'vant'
import { IconRegister } from '@/components/Icon'
import MyProgressBar from '@/components/MyProgressBar'
import { LoadingToast } from '@/components/Loading'
import Scanner from '@/components/Scanner'
import { device, user ,order} from '@/store'
import { setReaded } from '@/components/Shepherd/control'
import * as persistedstate from '@/utils/persistedstate'
import * as api from '@/api'
import SKUPC from '@/views/components/Sku/Sku.vue'

setReaded // 为了使 Shepherd 的 persistedstate 注册，不要删这行

const router = useRouter()
const route = useRoute()

/* 小程序 重新拉取缓存数据 */
const url = new URL(location.href)
// 设置小程序环境 后门
if (url.searchParams.get('isWeChatMini')) {
  device.setIsWeChatMini(true)
}


const uuid = url.searchParams.get('uuid')
const fair_id = url.searchParams.get('fair_id')
/*
console.log(fair_id)
//有UUID和fair_id 并且是小程序进来的
if (device.isWeChatMini && fair_id  && uuid && url.searchParams.get('reload_storage')) {
  console.log('fair_id')
  console.log(user.selectMeeting)
  user.setSelectMeeting(Number(fair_id))

  persistedstate.setState('fair_id', Number(fair_id), persistedstate.storage)
  console.log(user.selectMeeting)
}
*/


if (device.isWeChatMini && uuid && url.searchParams.get('reload_storage')) {
  try {
    persistedstate.uuid.value = uuid!

    const { data } = await persistedstate.getCloudCache()

    persistedstate.storageKeys.forEach((key) => {
      const value = data?.[key] ?? {}
      if (key === 'hdbuser') {
        // 此 key 请手动与 store.user 储存所用的键名保持一致
        value.uuid = uuid
/*        if(fair_id){
          user.setSelectMeeting(Number(fair_id))
          value.selectMeeting = Number(fair_id)
        }*/
      }
      persistedstate.setState(key, value, persistedstate.storage)
    })
    if(fair_id && Number(fair_id) !== user.selectMeeting ){
      user.setSelectMeeting(Number(fair_id))
    }
  } catch (error) {
    console.error(error)
  } finally {
    url.searchParams.delete('reload_storage')
    url.searchParams.delete('uuid')
    setTimeout(async ()=>{
      location.replace(url.toString())
      await new Promise(() => {}) // 页面跳转前 阻止渲染
    },100)
  }
}


if(device.isPC){
  document.querySelector('#app').classList.add('pc-app')
}else{
  document.querySelector('#app').classList.remove('pc-app')
}

if(device.isMobile){
  document.querySelector('#app').classList.add('mobile-app')
}else{
  document.querySelector('#app').classList.remove('mobile-app')
}

persistedstate.ready.value = true

watch(
  () => user.token,
  () => (persistedstate.token.value = user.token),
  {
    immediate: true,
  }
)
watch(
  () => user.uuid,
  () => (persistedstate.uuid.value = user.uuid),
  {
    immediate: true,
  }
)


watch(
  () => route.name,
  (newval,oldval) => {
    if(newval) document.querySelector('#app').classList.add(newval+'-app_page')
    if(oldval) document.querySelector('#app').classList.remove(oldval+'-app_page')
  },
  {
    immediate: true,
  }
)

/*routerKey  根据页面的 enforce 强制 刷新 页面缓存  只刷新keepAlive不为true的页面*/
const routerKey = ref(0)
watch(
  () => route.query?.enforce,
  (newval) => {
    if(newval) routerKey.value++
  },
  {
    immediate: true,
  }
)


/* keep alive 的路由名称 */
const keepAliveIncludes = router
  .getRoutes()
  .filter((router) => router.meta?.keepAlive)
  .map((router) => router.name as string)

/* 获取当前登录用户信息 */
const userResponse = api.useQuery(() => api.user.getLoginUser(), {
  immediate: false,
  async onError(error) {
    if (user.token) {
      await Dialog.alert({
        title: '获取用户信息失败',
        message: error.message,
        confirmButtonText: '重新请求',
      })
      userResponse.refetch()
    } else {
      api.errorProcessor({ error })
    }
  },
})
watch(
  () => userResponse.data,
  (data) => {
    user.setUserData(data ? data : undefined)
  }
)



/* token，选择组织 更新 重新获取用户信息 */
watch(
  () => [user.token, user.selectCompany],
  async ([token]) => {
    user.setUserData()
    if (!token) return

    userResponse.refetch()
  },
  {
    immediate: true,
  }
)

/* 登出 */
watch(
  () => user.token,
  async (token) => {
    if (token) return
    await router.isReady()
    // 未登录
    router.push({
      name: 'Login',
    })
  }
)




const orderResponse = ref();
watch(
  () => user.selectShop,
  async (data) => {
    if(data){
      orderResponse.value = api.useQuery(() => api.user.getOrderStatus({
        fair_id:user.selectMeeting,
        shop_id:user.selectShop
      }), {
        immediate: true,
        async onError(error) {
        },
      })
    }
  },
  {
    immediate: true,
  }
)


watch(
  () => orderResponse.value,
  async (value) => {
    if(value && value.data){
      order.setPass(value.data.is_verify)
    }
  },
  {
    immediate: true,
  }
)


/* 未选组织/未选订货会/未选门店 */
watch(
  () => [
    user.token,
    user.selectCompany,
    user.selectMeeting,
    user.selectShop,
    user.needFillInfo,
  ],
  async ([token, selectCompany, selectMeeting, selectShop, needFillInfo]) => {
    if (!token) return
    if (needFillInfo) {
      await router.isReady()
      router.push({
        name: 'FillInfo',
      })
      return
    }

    if (!selectCompany) {
      await router.isReady()
      router.push({
        name: 'SelectCompany',
      })
      return
    }

    if (!selectMeeting) {
      await router.isReady()
      router.push({
        name: 'SelectMeeting',
      })
      return
    }

    if (!selectShop) {
      await router.isReady()
      router.push({
        name: 'SelectShop',
      })
      return
    }
  }
)


/* 扫一扫 */
async function onSuccess(code: string) {

  if(code.includes('_')){
    const { data, error } =  await api.goods.getGoodsArtNo({
      goods_art_no:code,
      fair_id: user.selectMeeting,
      shop_id: user.selectShop,
    })
    api.errorProcessor({ error })
    code = data.goods_art_no
  }


  order.setTargetGoodsArtNo(code)

  if (route.name !== 'OrderList') {
    router.push({
      name: 'OrderList',
    })
  }


/*
  console.log(decodedText)
  order.setTargetGoodsArtNo(decodedText)

  if (route.name !== 'OrderList') {
    await router.replace({
      name: 'OrderList',
    })
  }*/

  device.setScanner(false)
}
</script>
