<template>
  <div class="login">
    <div class="login-logo">
      <img class="login-logo__img" src="@/assets/login-logo.png" />
    </div>

    <div class="login-form">
      <Input
        class="login-form__input"
        :class="{ 'login-form__input--focus': focus.username }"
        v-model="form.username"
        :placeholder="
          {
            [LOGIN_MODE.PASSWORD]: '请输入账号或手机号',
            [LOGIN_MODE.SMS]: '请输入手机号',
          }[mode]
        "
        @focus="focus.username = true"
        @blur="focus.username = false"
        tabindex="0"
        autofocus
        clearable
      />

      <Input
        v-if="mode === LOGIN_MODE.PASSWORD"
        class="login-form__input"
        :class="{ 'login-form__input--focus': focus.password }"
        v-model="form.password"
        placeholder="请输入密码"
        :type="visiblePassword ? 'text' : 'password'"
        @click-right-icon="visiblePassword = !visiblePassword"
        @focus="focus.password = true"
        @blur="focus.password = false"
        maxlength="30"
        tabindex="1"
        clearable
        @keypress.enter="submit"
      >
        <template #right-icon>
          <Icon class="eye" :use="visiblePassword ? 'eyeopen' : 'eyeclose'" />
        </template>
      </Input>

      <Input
        v-if="mode === LOGIN_MODE.SMS"
        class="login-form__input"
        :class="{ 'login-form__input--focus': focus.password }"
        v-model="form.password"
        placeholder="请输入短信验证码"
        @focus="focus.password = true"
        @blur="focus.password = false"
        maxlength="6"
        tabindex="1"
        clearable
        @keypress.enter="submit"
      >
        <template #right-icon>
          <Button
            class="login-form__send-sms"
            size="small"
            :disabled="countdown > 0"
            @click="emit('sendSMS')"
            type="default"
            round
          >
            {{ countdown > 0 ? `已发送 (${countdown})` : '获取验证码' }}
          </Button>
        </template>
      </Input>

      <Button
        class="login-form__mainbutton"
        round
        block
        @click="submit"
        :disabled="disabledMainButton"
        >登 录</Button
      >

      <div class="login-forgot" v-if="mode === LOGIN_MODE.PASSWORD">
        <span
          class="login-forgot__link"
          @click="emit('page', LOGIN_PAGE.FORGOT)"
          >忘记密码?</span
        >
      </div>
    </div>

    <div class="login-type">
      <div class="login-type__title">其他登录方式</div>

      <div class="login-type__list">
        <div
          class="login-type__item"
          v-if="mode !== LOGIN_MODE.PASSWORD"
          @click="mode = LOGIN_MODE.PASSWORD"
        >
          <IconLoginTypePassword class="login-type__icon" />
          <div class="login-type__text">账号密码</div>
        </div>
        <div
          class="login-type__item"
          v-if="mode !== LOGIN_MODE.SMS"
          @click="mode = LOGIN_MODE.SMS"
        >
          <IconLoginTypeSMS class="login-type__icon" />
          <div class="login-type__text">短信验证码</div>
        </div>
      </div>
    </div>

    <div class="login-contact">
      <div class="login-contact__list">
        <CallMe
          class="login-contact__item"
          :call="config.contact.service.telephone"
        >
          <Icon use="telephone" />客服电话
        </CallMe>
      </div>

      <div class="login-contact__tips">
        面向内部品牌体系在线成品批发交易平台，如您如无法登录请联系您的品牌服务人开通账号
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Toast } from 'vant'
import { useRoute, useRouter } from 'vue-router'

import { user } from '@/store'
import * as config from '@/config'
import { yup, publicKey } from '@/utils'
import * as api from '@/api'
import { LOGIN_PAGE, LOGIN_MODE } from '../config'

import Input from '@/components/Input'
import Icon from '@/components/Icon'
import Button from '@/components/Button'
import CallMe from '@/components/CallMe'

import IconLoginTypePassword from '@/assets/login-type--password.svg?component'
import IconLoginTypeSMS from '@/assets/login-type--sms.svg?component'
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

const emit = defineEmits<{
  (e: 'update:username', value: string): void
  (e: 'page', page: LOGIN_PAGE): void
  (e: 'sendSMS'): void
}>()

const props = defineProps<{
  username: string
  countdown: number | string
}>()

const router = useRouter()
const route = useRoute()
const mode = ref<LOGIN_MODE>(
  (() => {
    const key =
      (route.query?.login_mode as keyof typeof LOGIN_MODE) ||
      Object.keys(LOGIN_MODE).find((key) => {
        return user.lastLoginMode === LOGIN_MODE[key as keyof typeof LOGIN_MODE]
      }) ||
      'SMS'

    return LOGIN_MODE[key] ?? LOGIN_MODE.SMS
  })()
)

console.log(mode.value)

const form = ref({
  username: computed({
    get() {
      return props.username
    },
    set(value: string) {
      emit('update:username', value)
    },
  }),
  password: '',
})

const rules = computed(() => {
  return {
    [LOGIN_MODE.PASSWORD]: yup.object({
      username: yup.string().label('账号或手机号').required(),
      password: yup.string().required('请输入密码'),
    }),
    [LOGIN_MODE.SMS]: yup.object({
      username: yup
        .string()
        .label('手机号')
        .required()
        .length(11, '请输入有效的手机号'),
      password: yup.string().label('验证码').required(),
    }),
  }[unref(mode)]
})

async function submit() {
  if (disabledMainButton.value) return

  try {
    await rules.value.validate(unref(form))
  } catch (error: any) {
    Toast(error?.message || '数据校验错误')
    return
  }
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey)
  let _params = encryptor.encrypt(
    JSON.stringify({
      ...unref(form),
      type: unref(mode),
    })
  )

  const { error, data } = await api.user.login({
    _params_: _params,
  })

  user.setLastLoginMode(unref(mode))
  api.errorProcessor({ error })

  user.setToken(data.token)

  switch (Number(data.is_need_select_company)) {
    case 0: // 不需要选择组织
      user.setSelectCompany(true)
      break
    case 40006: // 未选择组织错误码？
    case 40007: // 没有可选组织
    default:
      user.setSelectCompany(true)
  }

  if (!data.is_real_name) {
    // 信息补全
    user.setNeedFillInfo(true)
    return
  }

  router.push({ name: 'Home' })
}

const focus = ref({
  username: false,
  password: false,
})

const visiblePassword = ref(false)

const disabledMainButton = computed(() => {
  const { username, password } = unref(form)
  return !(username && password && password.length >= 6)
})
</script>

<style lang="less" scoped>
.login {
  margin-bottom: rem(20);
  .safe-area-inset-bottom();

  .login-logo {
    padding-top: rem(60);

    .login-logo__img {
      width: rem(295);
      height: rem(104);
      display: block;
      margin: 0 auto;
    }
  }

  .login-form {
    padding: 0 rem(20);
    margin-top: rem(20);

    .login-form__input {
      font-size: rem(16);
      padding: rem(8) 0;

      & + .login-form__input {
        margin-top: rem(10);
      }

      &::after {
        display: block !important;
        border-bottom-color: @c-border;
        transform: scaleY(0.5);
        left: 0;
        right: 0;
      }

      &.login-form__input--focus::after {
        border-bottom-color: @c-primary;
      }

      :deep(.van-field__control) {
        height: rem(40);
      }
    }

    .login-form__mainbutton {
      margin-top: rem(20);
      font-size: rem(17);
      font-weight: bold;
    }
    .login-form__send-sms {
      background-color: rgba(@c-grey-light, 0.3);
      padding-left: rem(14);
      padding-right: rem(14);
    }
  }

  .login-forgot {
    text-align: right;
    margin-top: rem(8);
    .login-forgot__link {
      color: @c-black;
      font-size: rem(14);

      &:active {
        opacity: 0.8;
      }
    }
  }

  .login-type {
    margin-top: rem(16);
    padding: 0 rem(10);

    .login-type__title {
      font-size: rem(14);
      color: @c-grey;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before,
      &::after {
        content: '';
        display: block;
        height: rem(1);
        background-color: @c-border;
        flex-grow: 1;
        margin: 0 rem(12);
        transform: scaleY(0.5);
      }
    }

    .login-type__list {
      display: flex;
      justify-content: space-around;
      margin-top: rem(16);
      padding: 0 rem(33);
    }

    .login-type__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      user-select: none;

      &:active {
        opacity: 0.8;
      }
    }

    .login-type__icon {
      width: rem(44);
      height: rem(44);
    }

    .login-type__text {
      font-size: rem(11);
      color: @c-black;
      margin-top: rem(20);
    }
  }

  .login-contact {
    .login-contact__list {
      display: flex;
      justify-content: space-around;
      margin: 0 rem(20);
      margin-top: rem(50);
    }

    :deep(.login-contact__item) {
      position: relative;
      padding-right: rem(23);
      padding-left: rem(50);
      line-height: rem(46);
      background-color: #f4f4f4;
      border-radius: rem(23);
      font-size: rem(16);
      color: @c-black-light;
      user-select: none;

      &:active {
        opacity: 0.8;
      }

      @icon-margin: rem(4);
      > .icon {
        border-radius: 50%;
        background-color: @c-primary;
        color: white;
        position: absolute;
        font-size: rem(46) - @icon-margin * 2;
        left: @icon-margin;
        top: @icon-margin;
      }
    }

    .login-contact__tips {
      font-size: rem(11);
      color: @c-grey;
      text-align: center;
      margin-top: rem(10);
      padding: 0 rem(20);
    }
  }

  .eye {
    font-size: rem(18);
  }
}
</style>
