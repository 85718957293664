import { RouteRecordRaw } from 'vue-router'

import Home from '@/views/Home/Index.vue'
import NotFound from '@/views/Home/NotFound.vue'
import WeappOnly from '@/views/Home/WeappOnly.vue'
import Login from '@/views/User/Login/Index.vue'
import live from './live'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      navigation: 'home',
      keepAlive: true,
    },
  },
  {
    path: '/custom-layout/:id',
    name: 'CustomLayout',
    component: () => import('@/views/Home/CustomLayout.vue'),
    meta: {
      keepAlive: true,
      title: '微页面',
    },
  },
  {
    path: '/zone',
    name: 'Zone',
    component: () => import('@/views/Home/Zone.vue'),
    meta: {
      keepAlive: true,
      title: '订货专区',
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Home/Search.vue'),
    meta: {
      keepAlive: true,
      title: '商品搜索',
    },
  },

  /* 商品 */
  {
    path: '/goods-list',
    name: 'GoodsList',
    component: () => import('@/views/Goods/List/Index.vue'),
    meta: {
      title: '商品列表',
      keepAlive: true,
    },
  },
  {
    path: '/goods-detail',
    name: 'GoodsDetail',
    component: () => import('@/views/Goods/Detail/Index.vue'),
    meta: {
      title: '商品详情',
    },
  },
  {
    path: '/goods-not-found',
    name: 'GoodsNotFound',
    component: () => import('@/views/Goods/Detail/NotFound.vue'),
    meta: {
      title: '商品不存在',
    },
  },

  /* 订单 */
  {
    path: '/order',
    name: 'OrderList',
    component: () => import('@/views/Order/Index.vue'),
    meta: {
      title: '订单列表',
      navigation: 'order',
      pc: true,
    },
  },
  {
    path: '/analysisorder',
    name: 'AnalysisOrder',
    component: () => import('@/views/Order/Search.vue'),
    meta: {
      title: '订单列表',
      navigation: 'order',
    },
  },

  /* 支付 */
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/views/Payment/Checkout.vue'),
    meta: {
      title: '意向金结算页',
      pc: true,
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/views/Payment/Payment.vue'),
    meta: {
      title: '支付页',
      weapp: '/pages/fairpayment/index',
      pc: true,
    },
  },
  {
    path: '/payment-success',
    name: 'PaymentSuccess',
    component: () => import('@/views/Payment/Result/Success.vue'),
    meta: {
      title: '支付成功',
      pc: true,
    },
  },
  {
    path: '/payment-failure',
    name: 'PaymentFailure',
    component: () => import('@/views/Payment/Result/Failure.vue'),
    meta: {
      title: '支付失败',
      pc: true,
    },
  },
  {
    path: '/payment-wait',
    name: 'PaymentWait',
    component: () => import('@/views/Payment/Result/Wait.vue'),
    meta: {
      title: '等待回执',
      pc: true,
    },
  },

  {
    path: '/bank',
    name: 'Bank',
    component: () => import('@/views/Payment/Bank/Index.vue'),
    meta: {
      title: '我的银行卡',
      pc: true,
    },
  },
  {
    path: '/bank/add',
    name: 'BankAdd',
    component: () => import('@/views/Payment/Bank/Add.vue'),
    meta: {
      title: '增加银行卡',
      pc: true,
    },
  },

  /* 分析 */
  {
    path: '/analysis',
    name: 'Analysis',
    component: () => import('@/views/Analysis/Index.vue'),
    meta: {
      title: '分析',
      navigation: 'analysis',
    },
  },
  {
    path: '/analysis-rank',
    name: 'AnalysisRank',
    component: () => import('@/views/Analysis/Rank.vue'),
    meta: {
      title: '排行榜',
      keepAlive: true,
    },
  },
  {
    path: '/alldraw',
    name: 'AllDrawGoods',
    component: () => import('@/views/Analysis/AllDrawGoods.vue'),
    meta: {
      title: '大家都有订',
      keepAlive: true,
    },
  },
  {
    path: '/target-analysis',
    name: 'TargetAnalysis',
    component: () => import('@/views/Analysis/Target.vue'),
    meta: {
      title: '指标达成分析',
    },
  },
  {
    path: '/multi-order',
    name: 'MultiOrder',
    component: () => import('@/views/Analysis/Order.vue'),
    meta: {
      title: '多维分析',
    },
  },
  {
    path: '/construction-order',
    name: 'ConstructionAnalysis',
    component: () => import('@/views/Analysis/Construction.vue'),
    meta: {
      title: '订货结构分析',
    },
  },

  /* 登录相关 */
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录',
      weapp: '/pages/index/index',
      noAuth: true,
    },
  },
  {
    path: '/auto-login',
    name: 'AutoLogin',
    component: () => import('@/views/User/Login/AutoLogin.vue'),
    meta: {
      noAuth: true,
      title: '登录',
      skipGuard: true,
    },
  },
  {
    path: '/select-company',
    name: 'SelectCompany',
    component: () => import('@/views/User/Login/SelectCompany.vue'),
    meta: {
      title: '选择采购身份',
      pc: true,
    },
  },
  {
    path: '/select-meeting',
    name: 'SelectMeeting',
    component: () => import('@/views/User/Login/SelectMeeting.vue'),
    meta: {
      title: '选择订货会',
      pc: true,
    },
  },
  {
    path: '/fill-info',
    name: 'FillInfo',
    component: () => import('@/views/User/Login/FillInfo.vue'),
    meta: {
      title: '信息补全',
      pc: true,
    },
  },

  {
    path: '/select-shop',
    name: 'SelectShop',
    component: () => import('@/views/User/Login/SelectShop/Index.vue'),
    meta: {
      title: '选择订货门店',
      pc: true,
    },
  },

  /* 用户心中 */
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('@/views/User/Index/Index.vue'),
    meta: {
      title: '我的',
      navigation: 'mine',
    },
  },
  /* 期货订单详情 */
  {
    path: '/WholeSalePay',
    name: 'WholeSalePay',
    component: () => import('@/views/Payment/WholeSalePay.vue'),
    meta: {
      title: '银行转账打款',
      weappPath: 'pages/mine/wholesalepay/index',
    },
  },
  {
    path: '/Redirectbank',
    name: 'Redirectbank',
    component: () => import('@/views/Home/Redirectbank.vue'),
    meta: {
      noAuth: true,
      skipGuard: true,
      title: '好订宝',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/User/Settings.vue'),
    meta: {
      title: '设置',
    },
  },
  {
    path: '/abnormal-settings',
    name: 'AbnormalSettings',
    component: () => import('@/views/User/AbnormalSettings.vue'),
    meta: {
      title: '异常检测配置',
    },
  },
  {
    path: '/edit-password',
    name: 'EditPassword',
    component: () => import('@/views/User/EditPassword.vue'),
    meta: {
      title: '修改密码',
    },
  },
  {
    path: '/print',
    name: 'HDBLabelPrint',
    component: WeappOnly,
    meta: {
      title: '标签打印',
      weapp: '/pages/label/print/index',
    },
  },
  {
    path: '/scan-check',
    name: 'HDBScanCheck',
    component: () => import('@/views/User/ScanCheck/Index.vue'),
    meta: {
      title: '盘点单',
    },
  },
  {
    path: '/scan-check/:id',
    name: 'HDBScanCheckDetail',
    component: () => import('@/views/User/ScanCheck/Detail.vue'),
    meta: {
      title: '扫码盘点详情',
    },
  },
  {
    path: '/sample-review',
    name: 'SampleReview',
    component: () => import('@/views/User/SampleReview/Index.vue'),
    meta: {
      title: '样品评审',
    },
  },

  {
    path: '/review-analysis',
    name: 'ReviewAnalysis',
    component: () => import('@/views/User/ReviewAnalysis/Index.vue'),
    meta: {
      title: '评审多维分析',
    },
  },

  {
    path: '/sample-order',
    name: 'SampleOrder',
    component: () => import('@/views/Analysis/SampleOrder.vue'),
    meta: {
      title: '样品订单',
    },
  },
  {
    path: '/sample-order',
    name: 'SampleOrder',
    component: () => import('@/views/Analysis/SampleOrder.vue'),
    meta: {
      title: '样品订单',
    },
  },
  {
    path: '/class-rank',
    name: 'BottomClass',
    component: () => import('@/views/Analysis/BottomClass.vue'),
    meta: {
      title: '款式排行榜',
    },
  },
  {
    path: '/subclass-rank',
    name: 'ClassRank',
    component: () => import('@/views/Analysis/ClassRank.vue'),
    meta: {
      keepAlive: true,
      title: '子类排行榜',
    },
  },
  {
    path: '/ai-create',
    name: 'AICreate',
    component: () => import('@/views/Goods/Create/Index.vue'),
    meta: {
      title: 'AI创款',
    },
  },
  {
    path: '/ai-select',
    name: 'AISelect',
    component: () => import('@/views/Goods/Create/Select.vue'),
    meta: {
      title: 'AI选款',
    },
  },
  /* 其他 */
  {
    path: '/scan',
    name: 'Scan',
    component: WeappOnly,
    meta: {
      weapp: '/pages/scan/index',
    },
  },
  ...live,
  /* 准备页 特殊功能页 详情见 plugins/firstVisit */
  {
    path: '/Ready',
    name: 'Ready',
    component: {},
    meta: {
      noAuth: true,
      skipGuard: true,
    },
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
]
