<template>
  <Overlay
    v-model:show="state.InformShow"
    :style="{ borderRadius: '5px' }"
    @click-overlay="CloseInform"
    :key="state.InfromNum"
    :z-index="1000"
  >
    <div class="InformCenter">
      <div class="InformBox">
        <img src="@/assets/images/goodstips.png" />
        <!-- <div class="tips">*支付尾款后才会发货（已支付尾款用户请忽略）</div> -->
        <div class="InformScroll">
          <div
            class="InformContent flex between"
            v-for="(item, index) in state.police"
            :key="index"
          >
            <div class="InfromTitle">货号：{{ item.goods_art_no }}</div>
            <div class="InformItem">{{ item.color }}</div>
          </div>
        </div>
      </div>
      <div @click="CloseInform" class="closeBtn">
        <img src="@/assets/close.svg" />
      </div>
    </div>
  </Overlay>
</template>

<script lang="ts">
import { defineComponent, reactive, onActivated } from 'vue'
import { Overlay } from 'vant'
import Button from '@/components/Button'
import * as api from '@/api'
import { user } from '@/store'

export default defineComponent({
  components: {
    Overlay,
    Button,
  },
  setup() {
    const state = reactive({
      InformShow: false,
      InfromNum: 0,
      police: [],
    })
    const CloseInform = () => {
      state.InformShow = false
    }
    const getPolicy = async () => {
      const { data, error } = await api.user.getCleanGoodsNotice({
        fair_id: user.selectMeeting!,
        shop_id: user.selectShop!,
      })
      api.errorProcessor({ error })
      state.police = data
      if (data[0]) {
        state.InformShow = true
      }
    }
    getPolicy()
    onActivated(() => {
      state.InfromNum++
    })
    return {
      state,
      CloseInform,
    }
  },
})
</script>

<style lang="less" scoped>
.InformBox {
  width: rem(280);
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  img {
    width: rem(280);
    height: rem(94);
    display: block;
  }
  .InformScroll {
    max-height: rem(263);
    overflow-y: scroll;
  }
  .tips {
    color: #666;
    background: #f2f2f2;
    font-size: rem(12);
    height: rem(32);
    line-height: rem(32);
    padding: 0 rem(10);
  }
  .InformContent {
    padding: rem(10);
    padding-left: rem(25);
    position: relative;
    align-items: center;
    display: flex;
    height: rem(21);
    line-height: rem(21);
    justify-content: space-between;
    .InfromTitle {
      display: flex;
      color: #333333;
      font-size: rem(15);
      .InfromEllipse {
        width: rem(4);
        height: rem(13);
        margin-right: rem(7);
        background: #f76962;
        border-radius: rem(50);
      }
    }
    .InformItem {
      display: flex;
      font-size: rem(15);
      align-items: center;
      color: #666666;
      .InformCircle {
        width: rem(5);
        height: rem(5);
        margin-right: rem(7);
        background: #333333;
        border-radius: rem(999);
      }

      .discount {
        color: #fa6144;
        font-weight: 600;
        margin-left: rem(7);
        margin-right: rem(2);
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: rem(11);
      width: rem(5);
      height: rem(5);
      border-radius: rem(999);
      background: #333333;
    }
  }
  .NeverBtn {
    margin-top: rem(16);
    padding-bottom: rem(16);
    text-align: center;
    display: block;
    background: #ffffff;
    font-size: rem(14);
    color: #524f4f;
    border: 0;
  }
}
.DetailBtn {
  margin-top: rem(16);
  display: flex;
  justify-content: center;
  :deep(.van-button) {
    height: rem(34);
    display: block;
    background: linear-gradient(270.27deg, #fa5d38 0.46%, #f56c6c 97.62%);
    color: #ffffff;
    font-size: rem(14);
    border-radius: rem(450);
    font-size: rem(14);
    width: rem(226);
  }
}
.closeBtn {
  margin-top: rem(16);
  display: flex;
  justify-content: center;
  img {
    width: rem(24);
    height: rem(24);
  }
}
.InformCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
