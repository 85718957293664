import { Router } from 'vue-router'
import { user, device } from '@/store'
import * as persistedstate from '@/utils/persistedstate'
import { REDIRECT_KEY, createRedirectTo } from './loginRedirect'

/**
 * 除了注册页，当没有 token 则跳转至注册页
 * @param router
 */
export function authGuard(router: Router) {
  router.beforeEach((to, from, next) => {
    if (!persistedstate.ready.value) return next(false)

    /* 跳过路由守卫 */

    if (to.meta.skipGuard) return next()

    if (user.token /* 已登录 */) {
      if (to.name === 'FillInfo') {
        if (user.needFillInfo) {
          // 信息补全 仅在登录后允许访问
          // needFillInfo 仅会在登录后设置
          return next()
        } else {
          return next({ name: 'Home' })
        }
      }
      if (user.selectCompany /* 已选企业 */) {
        if (user.selectMeeting /* 已选订货会 */) {
          if (user.selectShop /* 已选门店 */) {
          } else {
            /* 未选门店 */
            if (to.name !== 'SelectShop') {
              return next({
                name: 'SelectShop',
                query: {
                  [REDIRECT_KEY]: createRedirectTo(to),
                },
              })
            }
          }
        } else {
          /* 未选订货会 */
          if (to.name !== 'SelectMeeting') {
            return next({
              name: 'SelectMeeting',
              query: {
                [REDIRECT_KEY]: createRedirectTo(to),
              },
            })
          }
        }
      } else {
        /* 未选企业 */
        if (to.name !== 'SelectCompany') {
          return next({
            name: 'SelectCompany',
            query: {
              [REDIRECT_KEY]: createRedirectTo(to),
            },
          })
        }
      }
    } else {
      /* 未登录 */
      if (!to.meta.noAuth /* 需要权限的页面 */) {
        return next({
          name: 'Login',
          query: {
            [REDIRECT_KEY]: createRedirectTo(to),
          },
        })
      }
    }

    return next()
  })
}
